/* global  window, document, simplyCountdown, createCalendar, Waypoint */

'use strict';

(function start() {

    if (window.simplyCountdown) {
        simplyCountdown('.simply-countdown-one', {
            year: 2019,
            month: 8,
            day: 9,
            hours: 12, // Sommerzeit => 10:30 Uhr
            minutes: 30
        });
    }

    if (window.createCalendar) {
        var calendarConfig = {
            options: {
                id: 'saveTheDateBtn'
            },
            data: {
                title: 'Hochzeit Christoph und Katrin',
                address: 'Luisenpark Mannheim',
                start: new Date('August 09, 2019'),
                end: new Date('August 09, 2019'),
                fullDay: true
            }
        };

        document.querySelector('.container-save-the-date').appendChild(createCalendar(calendarConfig));
    }

    document.querySelectorAll('.dropdown .dropdown-toggle').forEach(function(toggleButton) {
        toggleButton.addEventListener('click', function(event) {
            let button = event.currentTarget;
            button.nextElementSibling.classList.toggle('show');
        });
    });
    
    document.querySelectorAll('.dropdown .dropdown-menu li').forEach(function(dropdownElement) {
        dropdownElement.addEventListener('click', function(event) {
            let listElement = event.currentTarget;
            let dropdownMenu = listElement.parentElement;
            let selectedValue = listElement.dataset.value;
            dropdownMenu.previousElementSibling.querySelector('.btn-text').textContent = selectedValue;
            dropdownMenu.classList.remove('show');
            document.querySelector('#peopleAttending').value = selectedValue;
        });
    });

    document.querySelectorAll('.radio-group').forEach(function(group){
        group.querySelectorAll('.pretty-radio-button').forEach(function(fakeRadioButton) {
            fakeRadioButton.addEventListener('click', function(event) {
                let actualRadioButton = event.currentTarget.dataset.actual;
                let actualButton = group.querySelector(actualRadioButton);
                actualButton.checked = true;
                
                document.querySelector('#peopleAttendingSection').classList.toggle('show', event.currentTarget.classList.contains('pretty-yes'));
            });
        }) 
    });

    let saveAttendanceButton = document.querySelector('#saveAttendance');
    if(saveAttendanceButton) {
        saveAttendanceButton.addEventListener('click', function(event) {
            if (window.fetch) {
                event.preventDefault();
                if (saveAttendanceButton.classList.contains('clickThrough')) {
                    return;
                }
                saveAttendanceButton.classList.add('clickThrough');

                let token = document.querySelector('#token').value;
                let attending = document.querySelector('.actual-radio-button:checked') ? document.querySelector('.actual-radio-button:checked').value : undefined;
                let numberOfGuestsAttending = document.querySelector('#peopleAttending') ? document.querySelector('#peopleAttending').value : undefined;
                let form = new window.FormData();
                form.append('token', token);
                form.append('attending', attending);
                form.append('numberOfGuestsAttending', numberOfGuestsAttending);
                form.append('fetch', true);
                window.fetch('./dataUpdate.php', {
                    method: "POST",
                    mode: "same-origin",
                    credentials: "same-origin",
                    body: form
                }).then((response) => {
                    return response.json();
                }).then((jsonText) => {
                    saveAttendanceButton.classList.remove('errorHappened', 'successfullySaved');
                    if (jsonText.success) {
                        window.requestAnimationFrame(() => { window.requestAnimationFrame(() => {
                            saveAttendanceButton.classList.add('successfullySaved');
                            saveAttendanceButton.classList.remove('clickThrough');
                        })});
                        let newText = 'nicht angemeldet';
                        if (attending === '1') {
                            if (numberOfGuestsAttending === '1') {
                                newText = 'mit 1 Person angemeldet';
                            } else {
                                newText = 'mit ' + numberOfGuestsAttending + ' Personen angemeldet';
                            }
                        }
                        document.getElementById('nrPeopleAttending').innerHTML = newText;
                    } else {
                        window.requestAnimationFrame(() => { window.requestAnimationFrame(() => {
                            saveAttendanceButton.classList.add('errorHappened');
                            saveAttendanceButton.classList.remove('clickThrough');
                        })});
                    }
                }).catch(function(error){
                    window.console.error(error);
                    saveAttendanceButton.classList.remove('errorHappened', 'successfullySaved');
                    window.requestAnimationFrame(() => { window.requestAnimationFrame(() => {
                        saveAttendanceButton.classList.add('errorHappened');
                        saveAttendanceButton.classList.remove('clickThrough');
                    })});
                });
            }
        });
    }

    /**
     * Make stuff animate when you scroll over it
     */
    function setupWaypoints() {
        document.querySelectorAll('.animate-box').forEach(function(animateBox){
            new Waypoint({
                element: animateBox,
                offset: '85%',
                handler: function (direction) {
                    if (direction === 'down' && !animateBox.classList.contains('animated-fast')) {
                        animateBox.classList.add('item-animate');
                        window.setTimeout(function () {
                            document.querySelectorAll('.item-animate').forEach(function (item, index) {
                                window.setTimeout(function () {
                                    var effect = item.dataset.animateEffect;
                                    if (effect === 'fadeIn') {
                                        item.classList.add('fadeIn', 'animated-fast');
                                    } else if (effect === 'fadeInLeft') {
                                        item.classList.add('fadeInLeft', 'animated-fast');
                                    } else if (effect === 'fadeInRight') {
                                        item.classList.add('fadeInRight', 'animated-fast');
                                    } else {
                                        item.classList.add('fadeInUp', 'animated-fast');
                                    }
                                    item.classList.remove('item-animate');
                                }, index * 200);
                            });
                        }, 100);
                    }
                }
            })
        });
    }


    /**
     * Initializes the go to top button
     */
    function setupGoToTop() {
        var goToTopButton = document.querySelector('.js-top');
        goToTopButton.addEventListener('click', function(event){
            event.preventDefault();

            document.getElementById('topOfPage').scrollIntoView({ block: 'end',  behavior: 'smooth' });
        })

        window.addEventListener('scroll', function (event) {
            goToTopButton.classList.toggle('active', event.currentTarget.scrollY > 200);
        });
    }

    // Parallax
    var setupParallax = function () {
        /**
         * The function for paralaxing the top banner
         */
        function parallax() {
            var $slider = document.getElementById("section-header");
        
            var yPos = window.pageYOffset / $slider.dataset.speed;
            
            var coords = '50% calc(50% + '+yPos+'px)';
            
            $slider.style.backgroundPosition = coords;
        }
        
        window.addEventListener("scroll", function(){
            parallax();	
        });
    };

    setupParallax();
    setupGoToTop();
    setupWaypoints();
    window.everythingFine = true;
}());